/*
* Styles
*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600');

@import "normalize.scss";

@mixin desktop-only {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin screen1024 {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin screen991 {
    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin ipad {
    @media only screen and (max-width: 800px) {
        @content;
    }
}

@mixin iphone {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

$blue: #0649fb;

* {
	box-sizing: border-box;
}

//dadadas;

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.3px;
    text-align: left;
    color: #354052;
    padding-top: 76px;
}

button, input {
	font-family: 'Montserrat', sans-serif !important;
}

img {
	max-width: 100%;
	height: auto;
}

h2 {
    font-size: 36px;
    font-weight: 300;
    line-height: 1.39;
    text-align: left;
    color: #0649fb;
    margin-top: 0;
}

p {
	margin-top: 0;
}

a {
    color: $blue;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
        color: #2b64fb;
    }
}

.container {
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    //border: solid 1px red;
}

.try {
	display: inline-block;
	width: 160px;
	height: 46px;
	line-height: 46px;
	border-radius: 4px;
	font-size: 18px;
	text-align: center;
	color: #ffffff !important;
	background: #ca53f8;
	text-decoration: none;
	
	&:hover {
		background: #d269f9;
	}
	
	&:active {
		background: #b44ce5;
	}
}

.btn {
	display: inline-block;
	height: 26px;
	line-height: 26px;
	border-radius: 4px;
	font-size: 12px;
	color: #fff !important;
	text-decoration: none;
	background: #0a47f5;
	padding: 0 20px;
	
	&:hover {
		background: #2c63f6;
	}
	&:active {
		background: #0541e1;
	}
}

.btn-main {
	display: inline-block;
	margin-top: 5px;
	height: 26px;
	line-height: 26px;
	border-radius: 4px;
	font-size: 12px;
	color: #fff !important;
	text-decoration: none;
	background: #0a47f5;
	padding: 0 10px;
	
	&:hover {
		background: #2c63f6;
	}
	
	&:active {
		background: #0541e1;
	}
}

.btn-get {
	display: inline-block;
	margin-top: 5px;
	height: 36px;
	line-height: 36px;
	border-radius: 4px;
	font-size: 12px;
	text-decoration: none;
	background: #0a47f5;
	padding: 0 20px;
	
	background: #fff;
	border-radius: 4px;
	border: solid 1px #efefef;
	color: #354052;
	&:hover {
		color: #fff;
		background: #2c63f6;
	}
}


.hero {
    display: flex;
    text-align: center;
    @include screen1024 {
	    flex-direction: column;
    }
    
    h2 {
        text-align: center;
        font-size: 26px;
        margin-bottom: 40px;
        @include iphone {
	        font-size: 20px;
        }
    }

    &__first {
        //background: #e8eefd;
        width: 50%;
        padding: 70px 70px 30px;
        position: relative;
        @include screen1024 {
	        width: 100%;
	        padding-bottom: 70px;
        }
        @include iphone {
	        padding: 50px 20px;
        }
        
        .bg1 {
	        background: #e8eefd;
	        position:absolute;
	        left:0;
	        top:100%;
	        right:0;
	        bottom:0;
        }
        
        h2, img {
	        position: relative;
	        opacity: 0;
	        top: 20px;
        }
    }

    &__second {
	    position: relative;
        
        width: 50%;
        padding: 70px 70px 30px;
        color: #fff;
        @include screen1024 {
	        width: 100%;
	        padding-bottom: 70px;
        }
        @include iphone {
	        padding: 50px 20px;
        }
        
        .bg2 {
	        position:absolute;
	        left:0;
	        top:100%;
	        right:0;
	        bottom:0;
	        background: $blue;
        }
        
        h2 {
            color: #fff;
            position: relative;
            opacity: 0;
            top:20px;
        }
        
        p {
	        max-width: 350px;
	        margin: 0 auto;
	        padding: 50px 0;
	        position: relative;
	        opacity: 0;
            top:20px;
        }
        
        .mobile {
	        display: none;
	        margin: auto;
	        position: relative;
	        @include iphone {
		        display: block;
	        }
        }
        
        .desktop {
	        display: block;
	        margin: auto;
	        position: relative;
	        opacity: 0;
            top:20px;
	        @include iphone {
		        display: none;
	        }
        }
        
        .try  {
	        position: relative;
	        opacity: 0;
            top:20px;
        }
        
    }
}

.clients {
	background: #f9f9fa;
	padding: 100px 0;
	
	@include screen1024 {
		padding: 50px 0 30px;
	}
	
	
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		
		@include screen1024 {
			flex-direction: column;
			text-align: center;
		}
	}
	
	&__text {
		width: 30%;
		
		@include screen1024 {
			width: 40%;
		}
		@include iphone {
			width: 90%;
		}
		
	}
	
	&__list {
		width: 70%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 30px;
		
		@include screen1024 {
			padding: 30px 30px 0;
		}
		@include iphone {
			flex-direction: column;
		}
		
		img {
			margin: 20px;
		}
	}
}

.collecting {
	//border: solid 1px black;
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		
		@include screen1024 {
			flex-direction: column;
		}
	}
	
	h2 {
		margin-bottom: 15px;
		@include screen1024 {
			text-align: center;
		}
		@include iphone {
			font-size: 26px;
		}
	}
	
	&__text {
		//border: solid 1px red;
		width: 40%;
		padding-right: 50px;
		@include screen1024 {
			width: 50%;
			padding-top: 50px;
			padding-bottom: 40px;
			padding-right: 0;
			text-align: center;
		}
		@include iphone {
			width: 100%;
		}
	}
	&__image {
		//border: solid 1px red;
		@include screen1024 {
			width: 100%;
		}
		@include iphone {
			padding-bottom: 40px;
		}
	}
	.mobile {
		display: none;
		position: relative;
		text-align: center;
		padding-top: 70px;
		
		
		@include iphone {
			display: block;
			margin: auto;
		}
		.idea {
			top: 0;
			left: 50%;
			margin-left: -100px;
		}
		.idea__content, 
		.idea__point {
			opacity: 1;
			top: 0;
			transform: none !important;
		}
		.idea__point {
			top: 120px;
		}
	}
	.scene_3 {
		position: relative;
		padding-top: 150px;
		//padding-bottom: 150px;
		width: 670px;
		margin: auto;
		@include screen1024 {
			padding-bottom: 30px;
		}
		@include iphone {
			display: none;
		}
	}
	
	.idea {
		position: absolute;
		//border: solid 1px green;
		
		&__content {
			border-radius: 5px;
			background-color: #ffffff;
			box-shadow: 0 0 4px 0 rgba(231, 231, 231, 0.5);
			border: solid 1px #ebebeb;
			padding: 20px 20px 25px;
			opacity: 0;
			text-align: center;
			position: relative;
			top: 50px;
			
			p {
				margin-bottom: 5px;
			}
		}
		
		&__point {
			width: 20px;
			height: 20px;
			border-radius: 20px;
			font-size: 11px;
			background: $blue;
			color: #fff;
			text-align: center;
			line-height: 20px;
			position: absolute;
			left: 50%;
			bottom: -10px;
			margin-left: -10px;
			opacity: 0;
			transform: scale(0);
			
			&.done {
				background-color: #b44ce5;
				background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjRkZGRkZGIi8+Cjwvc3ZnPgo=);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 12px 12px;
				overflow: hidden;
				text-indent: -9999px;
			}
		}
		
		&--1 {
			left: 12px;
			top: 110px;
		}
		
		&--2 {
			left: 235px;
			top: 26px;
		}
		
		&--3 {
			left: 432px;
			top: 100px;
		}
	}
}

.manage {
	padding-bottom: 100px;
	padding-top: 150px;
	@include iphone {
		padding-bottom: 50px;
		padding-top: 10px;
	}
		
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		
		@include screen1024 {
			flex-direction: column;
		}
		
		
	}
	
	h2 {
		margin-bottom: 15px;
		@include screen1024 {
			text-align: center;
		}
		@include iphone {
			font-size: 26px;
		}
	}
	
	&__text {
		//border: solid 1px red;
		width: 50%;
		margin-top: -80px;
		@include screen1024 {
			width: 50%;
			margin-top: 0;
			padding-top: 50px;
			padding-bottom: 40px;
			padding-right: 0;
			text-align: center;
			order: 1;
		}
		@include iphone {
			width: 100%;
			
		}
	}
	&__image {
		//border: solid 1px red;
		width: 50%;
		@include screen1024 {
			width: 100%;
			order: 2;
		}
		@include iphone {
			//padding-bottom: 40px;
			padding-right: 60px;
			
		}
	}
	.mobile {
		display: none;
		@include iphone {
			display: block;
			margin: auto;
		}
	}
	.scene_4 {
		position: relative;
		width: 434px;
		height: 302px;
		
		
		//border: solid 1px red;
		background: url(../images/Illustration_4_bg.png) no-repeat center center;
		@include screen1024 {
			margin: auto;
			left: -40px;
		}
		@include iphone {
			display: none;
		}
		
		.btn {
			opacity: 0;
			position: absolute;
			right: 45px;
			bottom: 120px;
			z-index: 10;
		}
		
		&__1 {
			position:absolute;
			left:0;
			top:0;
			right:0;
			bottom:0;
			background: url(../images/Illustration_4_1.png) no-repeat center center;
			opacity: 0;
		}
		
		&__2 {
			position: absolute;
			left: 0;
			bottom: -20px;
			width: 92px;
			height: 230px;
			background: url(../images/Illustration_4_2.png);
			opacity: 0;
		}
		
		&__3 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_4_3.png) no-repeat center center;
			opacity: 0;
			z-index: 3;
		}
		&__4 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_4_4.png) no-repeat center center;
			opacity: 0;
			z-index: 4;
		}
		&__5 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_4_5.png) no-repeat center center;
			opacity: 0;
			z-index: 5;
		}
		&__6 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_4_6.png) no-repeat center center;
			opacity: 0;
			z-index: 6;
		}
	}
		
}

.analytics {
	padding: 100px 0;
	background: #f9f9fa;
	
	@include iphone {
		padding-bottom: 50px;
	}
		
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		
		@include screen1024 {
			flex-direction: column;
		}
		
		
	}
	
	h2 {
		margin-bottom: 15px;
		@include screen1024 {
			text-align: center;
		}
		@include iphone {
			font-size: 26px;
		}
	}
	
	&__text {
		//border: solid 1px red;
		width: 40%;
		@include screen1024 {
			width: 50%;
			margin-top: 0;
			padding-top: 50px;
			padding-bottom: 40px;
			padding-right: 0;
			text-align: center;
			order: 1;
		}
		@include iphone {
			width: 100%;
			
		}
	}
	&__image {
		//border: solid 1px red;
		width: 60%;
		padding-left: 60px;
		@include screen1024 {
			width: 100%;
			order: 2;
		}
		@include iphone {
			//padding-bottom: 40px;
			padding-right: 60px;
			
		}
	}
	.mobile {
		display: none;
		@include iphone {
			display: block;
			margin: auto;
		}
	}
	.scene_5 {
		position: relative;
		width: 469px;
		height: 366px;
		//border: solid 1px red;
		margin: auto;
		//background: url(../images/Illustration_5_all.png) no-repeat center center;
		
		@include screen1024 {
			margin: auto;
			left: -40px;
		}
		@include iphone {
			display: none;
		}
		
		
		&__1 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_1.png) no-repeat center center;
			opacity: 0;
			z-index: 2;
		}
		&__2 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_2.png) no-repeat center center;
			opacity: 0;
			z-index: 1;
		}
		&__3 {
			position:absolute;
			width: 126px;
			height: 230px;
			right:0;
			bottom:-20px;
			background: url(../images/Illustration_5_3.png) no-repeat center center;
			opacity: 0;
			z-index: 3;
		}
		&__4 {
			position:absolute;
			left:0;
			top:10px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_4.png) no-repeat center center;
			opacity: 0;
			z-index: 3;
		}
		&__5 {
			position:absolute;
			left:0;
			top:20px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_5.png) no-repeat center center;
			opacity: 0;
			z-index: 5;
		}
		
		// dots
		&__6 {
			position:absolute;
			left:0;
			top:30px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_6.png) no-repeat center center;
			opacity: 0;
			z-index: 6;
		}
		&__7 {
			position:absolute;
			left:0;
			top:30px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_7.png) no-repeat center center;
			opacity: 0;
			z-index: 6;
		}
		&__8 {
			position:absolute;
			left:0;
			top:30px;
			right:0;
			bottom:0;
			background: url(../images/Illustration_5_8.png) no-repeat center center;
			opacity: 0;
			z-index: 6;
		}
		
		// bars
		
		&__9 {
			position:absolute;
			left:136px;
			bottom:156px;
			background: $blue;
			width: 25px;
			height: 0px;
			z-index: 6;
		}
		&__10 {
			position:absolute;
			left:178px;
			bottom:156px;
			background: $blue;
			width: 25px;
			height: 0px;
			z-index: 6;
		}
		&__11 {
			position:absolute;
			left:220px;
			bottom:156px;
			background: $blue;
			width: 25px;
			height: 0px;
			z-index: 6;
		}
		
	}

}

.pricing {
	padding: 100px 0;
	
	@include iphone {
		padding: 40px 0;
	}
	
	h2 {
		text-align: center;
	}
	
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		
		@include iphone {
			flex-direction: column;
		}
	}
	
	.price {
		width: 270px;
		margin: 0 30px 30px;
		padding-bottom: 30px;
		text-align: center;
		border-radius: 7px;
		background-color: #ffffff;
		box-shadow: 0 2px 4px 0 rgba(211, 211, 211, 0.5), 0 0 16px 0 rgba(225, 225, 225, 0.5);
		overflow: hidden;
		
		&__title {
			font-size: 22px;
			color: #fff;
			background: $blue;
			padding: 10px;
		}
		
		&__employers {
			display: inline-block;
			border-bottom: solid 1px #354052;
			margin: 30px 0;
		}
		&__price {
			height: 110px;
			.number {
				color: $blue;
				font-size: 60px;
				line-height: 1;
			}
			.currency {
				position: relative;
				top: -30px;
			}
		}
		.btn-main {
			background: none;
			border-radius: 4px;
			border: solid 1px #efefef;
			color: #354052;
			&:hover {
				color: #fff;
				background: #2c63f6;
			}
		}
	}
}

.better {
	background: $blue;
	position: relative;
	color: #fff;
	text-align: center;
	padding: 150px 20px;
	margin-bottom: 140px;
	h2 {
		color: #fff;
		text-align: center;
		margin: 0 0 10px;
		opacity: 0;
		position: relative;
		top: 40px;
	}
	
	p {
		max-width: 400px;
		margin: 0 auto 40px;
		opacity: 0;
		position: relative;
		top: 40px;
	}
	.girl {
		position: absolute;
		left: 50%;
		bottom: -84px;
		margin-left: -40px;
		width: 81px;
		height: 168px;
		background:  url(../images/girl.svg);
	}
}

.footer {
	padding: 0 20px;
	
	@include ipad {
		text-align: center;
	}
	
	.container {
		overflow: hidden;
		border-top: solid 2px #e6e6e6;
		padding: 50px 0;
		
		@include iphone {
			padding: 25px 0;
		}
		
		.email {
			float: right;
			@include ipad {
				float: none;
			}
		}
		
		nav {
			float: left;
			
			@include ipad {
				float: none;
				border-bottom: solid 2px #e6e6e6;
				padding-bottom: 46px;
				margin-bottom: 40px;
				
			}
			
			@include iphone {
				padding-bottom: 25px;
				margin-bottom: 30px;
			}
			
			a {
				color: #354052;
				text-decoration: none;
				margin-right: 15px;
				
				@include ipad {
					margin: 0 10px;
				}
				
				@include iphone {
					display: block;
					margin:  8px 0;
				}
				
				&:hover {
					color: $blue;
				}
			}
		}
	}
}

.header {
	padding: 0 40px;
	height: 76px;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 999;
	background: #fff;
	
	@include ipad {
		padding: 0 20px;
	}
	
	&__logo {
		float: left;
		margin-top: 22px;
		
	}
	
	&__nav {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px 30px 0;
		float: right;
		font-size: 15px;
		
		@include ipad {
			position: fixed;
			z-index: 100;
			opacity: 0;
			visibility: hidden;
			left: 0;
			top: 76px;
			right: 0;
			bottom: 0;
			background: $blue;
			flex-direction: column;
			transition: all .3s ease-in-out;
			
			&.open {
				visibility: visible;
				opacity: 1;
				transition: all .3s ease-in-out;
			}
		}
		
		a {
			text-decoration: none;
			margin-right: 25px;
			color: #354052;
			
			&:hover {
				color: $blue;
			}
			
			@include ipad {
				display: block;
				margin:  10px 0;
				color: #fff !important;
			}
		}
	}
	
	&__lang {
  	float: right;
  	margin: 27px 50px 0 0;
  	
  	@include ipad {
    	position: absolute;
    	z-index: 999;
    	right: 40px;
    	top: 1px;
  	}
	}
	
	.btn-main {
		float: right;
		margin: 20px 0 0;
		height: 36px;
		line-height: 36px;
		padding: 0 20px;
		font-size: 14px;
		
		@include ipad {
			display: none;
		}
	}
	
	.toggle {
		float: right;
		width: 30px;
		height: 27px;
		margin: 30px 0 0 0;
		display: none;
		
		@include ipad {
			display: block;
		}
		
		.bar1, .bar2, .bar3 {
			display: block;
	      width: 26px;
	      height: 2px;
	      background-color: $blue;
	      margin: 3px 0;
	      transition: 0.4s;
	    }
	
	    &.is-open {
	      z-index: 910;
	      .bar1 {
	        transform: rotate(-45deg) translate(-4px, 2px);
	       }
	      .bar2 { opacity: 0; }
	      .bar3 {
	        transform: rotate(45deg) translate(-5px, -3px);
	      }
	    }
	}
}

.get-started {
	padding-top: 100px;
	padding-bottom: 150px;
	overflow: hidden;
	
	@include iphone {
		padding: 40px 0 100px;
	}
	
	&__text {
		float: left;
		width: 50%;
		
		@include iphone {
			display: none;
		}
		
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				display: flex;
				align-items: center;
				margin-bottom: 25px;
				img {
					margin-right: 15px;
				}
			}
		}
	}
	
	&__form {
		float: left;
		width: 50%;
		border-radius: 5px;
		background-color: #ffffff;
		box-shadow: 0 0 10px 0 rgba(225, 225, 225, 0.5);
		border: solid 1px #ebebeb;
		padding: 50px;
		
		@include iphone {
			width: 100%;
			box-shadow: none;
			border: none;
			padding: 0px;
		}
		
		h3 {
			font-size: 26px;
			font-weight: 300;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.23;
			letter-spacing: normal;
			text-align: left;
			color: #0649fb;
			margin: 0 0 40px;
		}
		
		.form-group {
			display: block;
			overflow: hidden;
			position: relative;
			
			&.has-error {
				.form-control {
					border: solid 1px #e6525a !important;
				}
				
				&:after {
					content: "!";
					position: absolute;
					right: 10px;
					bottom: 33px;
					width: 20px;
					height: 20px;
					background: #e6525a;
					color: #fff;
					border-radius: 100%;
					text-align: center;
					line-height: 20px;
				}
				.help-block {
					position: absolute;
					right: 0;
					top: 0px;
					font-size: 12px;
					color: #e6525a;
				}
			}
			
			label {
				display: block;
				font-size: 12px;
				padding-bottom: 5px;
			}
			.form-control {
				-webkit-appearance: none;
				height: 46px;
				border-radius: 5px;
				background-color: #fafbfc;
				border: solid 1px #e8ebed;
				width: 100%;
				padding: 0 15px;
				margin-bottom: 20px;
				box-shadow: none !important;
				
				&:focus, &:active {
					border-color: $blue !important;
					box-shadow: none !important;
				}
			}
		}
		
		.btn {
			float: right;
			height: 46px;
			border-radius: 4px;
			font-size: 16px;
			cursor: pointer;
		}
	}
	
	&__success {
		text-align: center;
		h2 {
			margin-bottom: 15px;
			text-align: center;
			padding-top: 20px;
		}
		img {
			position: relative;
			left: -10px;
		}
	}
}






